<template>
    <LoadedContent class="menu-items" :loaded="menuLoaded" :error="menuError">
        <h1 >{{ menuName }}</h1>
        <div class="block">
            <MenuItemsBlock  :menuItems="items" :parentCode="null" />
        </div>
        <PageFooter>
            <div></div>
            <AddItemButton @click="openModal({},'edit')" />
        </PageFooter>
        <teleport to="#app">
            <MenuItemForm :baseData="activeItem"  :opened="openedModal === 'edit'" @close="closeModal()" />
            <MenuSetLink :baseData="activeItem"  :opened="openedModal === 'set_link'" @close="closeModal()" />
        </teleport>
    </LoadedContent>
</template>

<script>
import { useStore } from 'vuex';
import LoadedContent from '@common/components/base/LoadedContent';
import { computed, defineAsyncComponent, provide, ref } from 'vue';
import MenuItemForm from '@admin/components/cms/menu/MenuItemForm.vue';
import AddItemButton from '@admin/components/base/AddItemButton';
import PageFooter from '@admin/components/base/PageFooter';

export default {
    name: "MenuItems",
    components: {
        LoadedContent,
        MenuItemsBlock: defineAsyncComponent(() => import(/* webpackChunkName: "menu-items-block" */'@admin/components/cms/menu/MenuItemsBlock.vue')),
        MenuSetLink: defineAsyncComponent(() => import(/* webpackChunkName: "menu-set-link" */'@admin/components/cms/menu/MenuSetLink.vue')),
        MenuItemForm,
        AddItemButton,
        PageFooter
    },
    setup(){
        const store = useStore();
        
        // store.dispatch('menu/fetchItems',{});

        store.dispatch('menu_item/fetchItems',{});

        const menuName = computed(() => store.getters['menu_item/getMenuName']);

        const items = computed(() => store.getters['menu_item/getItems']);

        const menuLoaded = computed(() => store.getters['menu_item/isLoaded']);
        const menuError = computed(() => store.getters['menu_item/isError']);

        const activeItem = ref(null);
        const openedModal = ref(null);

        const openModal = (menuItem,modal) => {
            activeItem.value = menuItem;
            openedModal.value = modal;
        }
        const closeModal = () => {
            activeItem.value = null;
            openedModal.value = null;
        }

        provide('openModal',openModal);

        return {
            menuName,
            items,
            menuLoaded,
            menuError,
            closeModal,
            activeItem,
            openModal,
            openedModal
        }

    }
}
</script>

<style scoped>
.block{
    margin: 1rem;
    flex-grow: 1;
}
</style>