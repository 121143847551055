<template>
    <Modal class="menu-item-form" @close="$emit('close')" :opened="opened">
        <template v-if="createItem" v-slot:header>
            {{ $t('controls.add_new_item')}}
        </template>
        <template v-else v-slot:header>
            {{ $t('cms.edit_menu_item')}}
        </template>
        
        <template v-slot:body>
            <BaseForm 
                :form_class="'form_small'" 
                @closeForm="closeForm()" 
                :cancelButton="''"
                :deleteButton="!createItem" >
            </BaseForm>
        </template>
    </Modal>
</template>

<script>
import BaseForm from '@common/components/crud/BaseForm.vue'
import Modal from "@common/components/Modal";
import useFormData from '@common/composable/BaseFormData';
import { useI18n } from 'vue-i18n';
import { watchEffect,ref } from 'vue';
import useProject from '@admin/composable/ProjectModel';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';


export default {
    name: "MenuItemForm",
    components: {
        BaseForm,
        Modal
    },
    emits: ['close'],
    props: {
        opened: Boolean,
        baseData: {
            type: Object,
            default: () => null
        },
        // createItem: {
        //     type: Boolean,
        //     default(){
        //         return false;
        //     }
        // }
    },
    setup(props,context){       
        const i18n = useI18n();
        const router = useRouter();
          
        const { data,setRows,store_action,formInit,formActions,code } = useFormData();
        formInit();

        const createItem = ref(false);
        // data.value = props.baseData;
        watchEffect(() => {
            // console.log(props.baseData)
            if(props.baseData === null){
                return;
            }
            data.value = Object.assign({},props.baseData);
            // data.value = props.baseData;
            code.value = props.baseData.code;
            store_action.value = 'menu_item/updateItem';
            if(!data.value.code){
                createItem.value = true;
                store_action.value = 'menu_item/createItem';
                data.value.menuCode = router.currentRoute.value.params.menu_key;
                data.value.type = 0;
                data.value.status = 20;

            }
        });
        const { 
            hasExtension
        } = useProject();   

        const statusOptions = [
            {
                title: i18n.t('controls.active'),
                code: 20,
            },
            {
                title: i18n.t('controls.disabled'),
                code: 0,
            },
        ];
        if(hasExtension("auth")){
            statusOptions.push(
                {
                    title: i18n.t('cms.private_access'),
                    code: 10,
                },
                {
                    title: i18n.t('cms.only_public'),
                    code: 30,
                }
            )
        }

        setRows([
            {
                labels: [i18n.t('cms.menu_item_name')],
                items: [
                    {
                        title: i18n.t('cms.menu_item_name'),
                        type: 'text',
                        key: "name",
                        class: "w-xl",
                        required: true,
                    }
                ]
            },
            {
                labels: [i18n.t('cms.menu_item_type')],
                items: [
                    {
                        title: i18n.t('cms.menu_item_type'),
                        type: 'select',
                        key: "type",
                        class: "w-l",
                        required: true,
                        options: () => [
                            // {
                            //     title: "",
                            //     code: ""
                            // },
                            {
                                title: i18n.t('cms.link'),
                                code: 0,
                            },
                            {
                                title: i18n.t('cms.header'),
                                code: 10,
                            },
                            {
                                title: i18n.t('cms.label'),
                                code: 20,
                            },
                            {
                                title: i18n.t('cms.divider'),
                                code: 30,
                            }
                        ]
                    }
                ]
            },
            {
                labels: [i18n.t('controls.status')],
                items: [
                    {
                        title: i18n.t('controls.status'),
                        type: 'select',
                        key: "status",
                        class: "w-l",
                        required: true,
                        options: () => statusOptions
                    }
                ]
            },
        ]);

        const closeForm = () => {
            data.value = {};
            context.emit('close');
        }

        const store = useStore();
        
        formActions.value.afterSubmit = () =>  closeForm();
        formActions.value.cancelForm = () =>  closeForm();
        formActions.value.deleteItem = () => {
            store.dispatch('menu_item/deleteItem',{code: code.value});
            closeForm();
        };

        return {
            closeForm,
            createItem
        }

    }
}
</script>

<style scoped>

</style>